import "./index.css";
import ADQCyclingLogoImage from "../../assets/illustrations/cycling-logo-adq.svg";

export const CyclingLogoADQ = () => {
  return (
    <div className="cycling-logo-adq">
      <div className="cycling-logo-adq__image">
        <img src={ADQCyclingLogoImage} alt="ADQ Cycling Logo" />
      </div>
      <div className="cycling-logo-adq__underline" />
    </div>
  );
};
